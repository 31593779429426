import React from "react";

const PrivacyPolicy = () => {
  return (
    <div id="privacy-policy">
      <h1>Mind Swirl Apps Privacy Policy</h1>
      May 29, 2023 <br/>
      <section>
        <span className="highlight">Mindswirl</span> believes that your privacy is
        our top priority. This policy describes the personally identifiable
        information and other data that we will collect from our applications, and
        how we might use and display such data to third parties. Your
        Installation, Use, and Submission of Information to us establish your
        consent to this Privacy Policy and the processing of your information as
        described in this policy. Please use the services only if you agree to
        this Privacy Policy. 
        <section>
            Please Note: If you or Your Legal Guardian disagree
            to any term provided herein, you may not install or use the apps and are
            requested to promptly uninstall the app from your device.
        </section>
      </section>
        <section>
        <span className="highlight">How We Use Your Personal Information</span>
      All user data including media files (audio, video, images) and documents
      (including contact lists) are securely handled by Our Apps by employing
      encryption. Moreover, the files that the user backs up on our servers are
      completely private and are in no way publicly visible or accessible. As
      per practice in vogue, the app’s personnel may need to access file
      contents in rare scenarios like 
      <section>
        Legal obligation
      </section>
      <section>
      Ensuring satisfactory
      user experience (e.g. debugging, performance testing, search result
      verification, recommendations, etc.)
      </section>

      <section>
        Enforcing our Terms of Service and Acceptable Use Policy.
      </section>

        <section>
        Such file access is limited to a very small number
      of people. We ensure the implementation of strict policy and access
      controls.
        </section>

        <section>
        Sharing of User Information
        </section>

          Any information collected by Our
      Apps is only for improving the user experience. All information collected
      is encrypted through industry-standard mechanisms. Our Apps employ best
      practices to safeguard user information from unauthorised visibility/
      access. In certain cases, Our Apps may disclose user information to 3rd
      parties for advertising purposes. Our Apps use advertising campaigns in a
      manner so as not to reveal the personal information of the users while
      providing a tailored advertisement to suit user requirements. In case of
      legal action, Our Apps may be obliged to disclose user information to
      third parties against some malicious acts on part of the user. Our Apps
      advises its users to take necessary precautions when sharing information
      with unknown 3rd parties.

      <section>
        Link to Third-Party Websites and Services
      </section>

      The
      APPS may contain links to other websites and online services, including
      third-party advertisements. If you choose to click through one of these
      other websites or online services, please note that any information you
      may provide will be subject to that website’s or service’s privacy policy
      and their terms & condition, and not to this Privacy Policy. We do not
      share your data with third-party websites or services, with the fact that
      a link to such websites or services does not appear in our APPS. You agree
      that our application will have no liability for any matters related to the
      third-party website or service that you provide information to, including
      their collection and handling of that information.

      <section>
        Adherence with Laws
      </section>

      <section>
        ("We") reserve the right to share your information with third parties if
        we believe such action is necessary in order to:
      </section>

      <section>
        Conform to the
        requirements of the law or to comply with the legal process served upon
        us.
      </section>

      <section>
      Protect or defend our legal rights or property.
      </section>

         <section>
         Investigate, prevent
      or take action regarding illegal activities, suspected fraud, situations
      involving potential threats to the physical safety of any person or
      violations of the terms and conditions of using our Apps and Services.
         </section>

         <section>
         Permissions Our Apps May Ask For
         </section>

         <section>
         Our apps may ask for the following
      permissions but that will be for the sole purpose of providing core
      functionality. There will be no invasion of privacy or unethical use of
      resources or data.
         </section>

         <section>
         Display over other apps
         </section>

         <section>
         Manage all files
         </section>

         <section>
         Camera
         </section>

         <section>
            Data Security
         </section>

         <section>
         Mind Swirl Apps has implemented technology and
      policies to safeguard your privacy from unauthorized access and improper
      use.
         </section>

         <section>
         Changes to our Privacy Policy
         </section>

         <section>
         This Privacy Policy may be updated for
      any reason e.g. legal, economic, public opinion, etc. We reserve the right
      to update the policy and have no obligation to actively inform users.
      Users are welcome to revisit the page for updates to the policy.
         </section>

               <section>
               Contact us
      </section> 
      <section>
      If you have any questions regarding privacy while using the apps, or
      have questions about our practices, please contact us via email at
      support@mindswirlapp.com
      </section>
        </section>
    </div>
  );
};

export default PrivacyPolicy;
