import React from 'react'
import BackgroundImage from "../images/background.jpg";

const Home = () => {
  return (
    <div>
        <div id='bg'>
            <img src ={BackgroundImage} alt="Background Image" />
            <div>
                <h1>Coming Soon</h1>
            </div>
        </div>
    </div>
  )
}

export default Home
